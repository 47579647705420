<script>
import Loader from "vue-element-loading";
import Vue from "vue";

import api from "@/api";
import { MyComponent } from "@/components/MyComponent.js";

export default {
  name: "register",
  components: {
    Loader,
  },
  mixins: [MyComponent],
  data: function () {
    return {
      loading: false,
      email: "",
      phone: "",
      password: "",
      confirm_password: "",
    };
  },

  methods: {
    register: function () {
      if (this.password.length < 8) {
        alert("Password must be 8 chars or more.");
        return;
      }
      if (this.password != this.confirm_password) {
        alert("Passwords do not match.");
        return;
      }

      const data = {
        password: this.password,
        email: this.email,
        phone: this.phone,
      };

      this.loading = true;

      api.Util.axios
        .post("/register/", data)
        .then((response) => {
          if (response.data.job != null) {
            Vue.$toast.success(
              "Registration successful. Check email for registration details."
            );
          } else {
            Vue.$toast.success("Registration successful.");
          }
          this.$router.push({
            name: "Login",
          });
        })
        .catch((err) => {
          console.error(err.response);
          if (err.response.data) {
            alert(err.response.data);
          } else {
            alert("Unable to register.");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPostregister: function () {
      var returnComponent = this.$route.params.ReturnComponent;
      if (returnComponent) {
        this.$router.push({ name: returnComponent });
      } else {
        api.Util.axios.get("/postregister").then((response) => {
          if (response.data.location) {
            window.location.replace(response.data.location);
          }
        });
      }
    },
  },
};
</script>

<template>
  <div id="register">
    <div class="page-header">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-6 col-lg-4" id="inner">
            <Loader :active="loading" />

            <div id="sec" class="box">
              <h3 class="text-center">Register</h3>

              <form role="form" @submit.prevent="register">
                <div class="form-group">
                  <label for="exampleInputEmail1">Email address</label>
                  <input
                    name="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    placeholder="Email address"
                    type="email"
                    v-model="email"
                    required
                  />
                </div>
                <!-- form-group// -->
                <div class="form-group">
                  <label for="exampleInputPhone">Phone</label>
                  <input
                    name="phone"
                    class="form-control"
                    id="exampleInputPhone"
                    placeholder="+254 712 345 678"
                    type="phone"
                    v-model="phone"
                  />
                </div>

                <div class="form-group">
                  <label for="exampleInputPassword1">Password</label>
                  <input
                    name="password"
                    id="exampleInputPassword1"
                    class="form-control"
                    placeholder="Create password"
                    type="password"
                    v-model="password"
                    required
                  />
                </div>
                <!-- form-group// -->

                <div class="form-group">
                  <label for="exampleInputPassword2">Confirm Password</label>
                  <input
                    class="form-control"
                    id="exampleInputPassword2"
                    placeholder="Repeat password"
                    type="password"
                    v-model="confirm_password"
                    required
                  />
                </div>
                <!-- form-group// -->

                <div class="form-group row justify-content-center">
                  <div class="col text-center">
                    <button type="submit" class="btn btn-primary btn-block">
                      Create Account
                    </button>
                  </div>
                </div>
                <!-- form-group// -->

                <p class="text-center">
                  Have an account?
                  <router-link :to="{ name: 'Login' }">Log In</router-link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#register {
  background: rgb(246, 246, 246);
  padding-top: 20px;
  padding-bottom: 20px;

  form > div {
    margin-bottom: 10px;
  }

  h3 {
    margin-bottom: 15px;
  }

  #sec {
    background: white;
  }
  .box {
    border: 1px solid #ccc;
    box-shadow: rgb(227, 228, 231) 0px 2px 0px 0px;
    padding: 15px;
  }
  .form-links {
    a:hover {
      text-decoration: underline;
    }
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    padding-left: 10px;
  }
  #log-btn {
    text-transform: uppercase;
    box-shadow: rgb(227, 228, 231) 0px 2px 0px 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
}
</style>
